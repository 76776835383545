<template>
  <div>
    <div desktop="12" tablet="8">
      <dl>
        <dt>Login successful</dt>
        <dt>Your browser should be redirected soon</dt>
      </dl>
    </div>
  </div>
</template>

<script>


export default {
beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.$router.replace("/callback")
      })
}
};
</script>

<style>
</style>
